// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
// button Override
.reject .ant-modal-content .ant-btn-dangerous,
.delete .ant-modal-content .ant-btn-dangerous {
  background-color: #d72c0d;
  border-color: #d72c0d;
}

@primary-color: #10655E;@info-color: #1890ff;