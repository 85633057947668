// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.summary-comments {  
  button {
    padding: 0;
    color: #10655E;
  }
}

@primary-color: #10655E;@info-color: #1890ff;