// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.profile-header {
  margin-bottom: 34px;
  display: flex;
  justify-content: space-between;
}

.profile-header > button {
  box-shadow: 0px 2px 0px 0px #0000000B;
  border-radius: 3px;
}

@primary-color: #10655E;@info-color: #1890ff;