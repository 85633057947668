// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.summary-card-actions {
  display: grid;
  gap: 16px;

  &__status {
    margin-bottom: 8px;

    .ant-alert {
      border: none;

      .anticon {
        margin-right: 12px;
      }
    }

    .ant-alert-info {
      box-sizing: border-box;
      background-color: #e6f7ff;

      .anticon-info-circle {
        color: #1890ff;
      }
    }
  }

  &__submit-edit {
    box-sizing: border-box;
    border-radius: 3px;
    box-shadow: 0px 2px 0px 0px #0000000b;

    svg {
      fill: #ffffff;
    }
  }

  .ant-btn-primary[disabled] {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 3px;

    svg {
      fill: #b8b8b8;
    }
  }

  .preview-delete-actions {
    display: flex;
    gap: 16px;
  }

  .preview-delete-actions :is(button, a) {
    width: 100%;
    font-size: 14px;
  }

  .published-course-link {
    padding: 0;
    height: 0;
    line-height: 0;
  }
}

@primary-color: #10655E;@info-color: #1890ff;