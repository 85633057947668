// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
@import "antd/lib/style/themes/default.less";

.list-items-loader {
  .ant-list-empty-text {
    color: @text-color;
  }
}

@primary-color: #10655E;@info-color: #1890ff;