// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
#app-dashboard-container {
  min-height: 100vh;

  .logo-container {
    padding: 20px;
    img {
      width: 100%;
    }
  }

  main {
    padding: 40px;
  }
}

header.ant-layout-header {
  background: white;
}

@primary-color: #10655E;@info-color: #1890ff;