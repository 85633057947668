// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.courseCardContainer {
  max-height: 58px;
  width: 100%;
  background-color: #FAFAFA;
  border: 1px solid #F0F0F0;
  padding: 8px 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;

  &__text {
    display: flex;
    flex-direction: column;
    flex-grow: 2;

    &__sub-text {
      font-size: 12px;
      color: #6d7175;
    }
  }

  &__status-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__remove-container {
    margin-left: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__remove-container button {
    height: 36px !important;
  }
}

@primary-color: #10655E;@info-color: #1890ff;