// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.course-action-card {

  h5 {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 16px;
  }

  h6 {
    color: #6d7175;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  button {
    font-size: 14px
  }


  .ant-card-body {
    padding: 0;
  }
  
  .ant-card-grid {
    width: 100%
  }

  .ant-btn-dangerous {
    border-color: #d72c0d;
    color: #d72c0d;
  }
}

@primary-color: #10655E;@info-color: #1890ff;