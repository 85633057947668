// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
@import 'antd/lib/style/themes/default.less';

.provider-select {
  color: @primary-color !important;
  margin-left: -20px !important;

  .ant-select-suffix {
    color: @primary-color !important;
  }
}

@primary-color: #10655E;@info-color: #1890ff;