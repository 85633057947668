:root {
  --primary-color: #10655e;
  
  --amplify-fonts-default-variable: 'inherit';
  --amplify-fonts-default-static: 'inherit';
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
  --amplify-components-button-primary-background-color: var(--primary-color);
  --amplify-components-button-primary-hover-background-color: #0e5a54;
  --amplify-components-button-primary-focus-background-color: #0e5a54;
  --amplify-components-button-hover-border-color: #0e5a54;
  --amplify-components-link-color: var(--primary-color);
  --amplify-components-link-hover-color: #0e5a54;
  --amplify-components-link-visited-color: var(--primary-color);
  --amplify-colors-border-primary: #d9d9d9;
  --amplify-colors-border-focus: var(--primary-color);
  --amplify-components-fieldcontrol-border-radius: 3px;
  --amplify-components-fieldcontrol-border-width: 1px;
  --amplify-components-fieldcontrol-focus-border-color: var(--primary-color);;
  --amplify-components-authenticator-router-box-shadow: 0px 0px 24px 0px #00000014;
  --amplify-components-authenticator-router-border-width: 1px;
  --amplify-components-loader-stroke-empty: var(--primary-color);
  --amplify-components-button-link-loading-color: var(--primary-color);
  --amplify-components-loader-stroke-filled: var(--primary-color);
  --amplify-components-button-primary-active-background-color: #0e5a54;
}

[data-amplify-authenticator][data-variation='modal'] {
  background: initial;
  position: initial;
  height: 100vh;
}
