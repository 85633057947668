// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
#root {
  height: 100%;
}

.login-container {
  width: 100%;
  max-width: 1200px;
  margin: auto auto;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &__card {
    display: flex;
    flex-direction: column;
    padding: 48px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    margin: 8px auto;
    min-width: 480px;

    .password-container {
      margin-top: 30px;
    }
  }
}

.bottom-signin {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #10655e;
}

.bottom-reset-password {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.bottom-reset-password p {
  height: 40px;
  width: 100%;
  text-align: center;
  line-height: 40px;
  color: #10655e;
}

.bottom-reset-password p:hover {
  cursor: pointer;
  background-color: #e6eefe;
  color: #10655e;
}

.need-help-signin {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.need-help-signin .arrow-icon {
  margin-left: 5px;
}

.title-signin {
  margin-bottom: 30px !important;
  font-size: 24px !important;
}

.border-radius-auth {
  border-radius: 5px !important;
}

.button-signin {
  margin-top: 30px;
}

@media only screen and (max-width: 500px) {
  .bottom-signin {
    font-size: 12px;
  }
}

.loading-container {
  width: 150;
  height: 150;
  display: grid;
  place-items: center;
  margin-inline: auto;
}

@primary-color: #10655E;@info-color: #1890ff;