// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.heading-3 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
}

// Custom for quill
.ql-container {
  font-size: 14px;
  font-family: inherit;
  min-height: 100px;
  background: #ffffff;
}

.ql-toolbar {
  background: #fbfbfb;
}

.has_errors {
  border: 1px solid #ff4d4f;
}

.ant-form-item-explain-error {
  margin-bottom: 1rem;
}

@primary-color: #10655E;@info-color: #1890ff;