// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@primary-color: #10655E;@info-color: #1890ff;