// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.header-container {
  #help-center-link {
    padding: 0;
    border: none;
  }

  .primary-sign-out {
    background-color: white;
    border: 1px solid @light-gray;
    color: rgba(0, 0, 0, 0.85);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 3px;
  }
}

@primary-color: #10655E;@info-color: #1890ff;