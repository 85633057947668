// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.discard-modal .ant-modal-content .ant-btn-dangerous {
  background-color: #d72c0d;
  border-color: #d72c0d;
}

.ant-modal-content .ant-modal-title {
  font-weight: 700;
}

@primary-color: #10655E;@info-color: #1890ff;