// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.container {
  display: flex;
  align-items: center;
  height: 100%;

  .header-end {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 40px;
  }

  h4.ant-typography {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
  }

  .primary-sign-out {
    background-color: white;
    border: 1px solid @light-gray;
    color: rgba(0, 0, 0, 0.85);
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 3px;
  }
  

  span[role='img'] {
    margin-left: 9px;
  }
}

@primary-color: #10655E;@info-color: #1890ff;