// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.to-courses-link {
  color: #6d7175;
}

div.lock-credential-alert {
  margin: -40px -40px 24px -40px;
}

.price-title > label {
  font-size: 16px;
}

.discount-desc-title #root_discountDesc__title {
  font-size: 14px;
}

.card-title {
  h2 {
    margin: 0;
  }

  .ant-tag {
    border-radius: 3px;
    border: none;
  }

  .ant-tag-default {
    background: @light-gray;
  }

  .ant-tag-processing {
    color: #874d00;
    background: #ffe58f;
  }

  .ant-tag-warning {
    color: #820014;
    background: #ffa39e;
  }

  margin-top: 14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.ql-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.error-detail {
  color: red;
}

.site-custom-tab-bar {
  z-index: 1;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
}

.rjsf {
  label:first-letter,
  legend:first-letter {
    text-transform: capitalize;
  }
}

div.ant-tabs-tab {
  padding: 0;
}

div.ant-tabs-nav.site-custom-tab-bar {
  z-index: 2;
}

@primary-color: #10655E;@info-color: #1890ff;