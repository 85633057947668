// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.summary-history {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;

    li span {
      color: #6d7175;
    }
  }
}

@primary-color: #10655E;@info-color: #1890ff;