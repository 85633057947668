// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.provider-list-table {
  .ant-table-cell {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

li.draft-courses {
  padding-left: 8px;
}

.draft-courses:hover  {
  background-color: #f5f5f5;
  cursor: pointer;
}

.card-published-cred-item {
  .ant-card-body.ant-card-body {
    padding: 0px
  }
}

.published-creds-table {
  thead {
    tr > th:last-child {
        width: 40%
    }
  }

  tbody {
      td {
          li {
              padding: 0px;
          }

          padding: 12px 20px;
      } 
  }
}
@primary-color: #10655E;@info-color: #1890ff;