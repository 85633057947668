// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.card-published-cred-item {
    .ant-card-body {
        padding: 0px
    }
}

.published-creds-table {
    thead {
        tr > th:last-child {
            width: 40%
        }
    }
    
    tbody {
        td {
            li {
                padding: 0px;
            }

            padding: 12px 20px;
        } 
    }
}

.card-wrapper {
  ul > li {
    text-align: left;
  }

  span:hover {
    color: rgba(0, 0, 0, 0.45);
    cursor: unset;
  }
}

@primary-color: #10655E;@info-color: #1890ff;