// common colors
@primary-color: #10655e;

@light-gray: #D9D9D9;
.alert-box {
  max-width: 400px;
  display: table;
  white-space: pre-wrap;
  word-wrap: break-word;

  .ant-alert {
    align-items: baseline;

    .anticon.ant-alert-icon {
      transform: translateY(15%); // align icon with text vertically
    }
  }
}

@primary-color: #10655E;@info-color: #1890ff;